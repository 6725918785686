<template>
    <b-table-simple responsive fixed>
        <thead>
            <tr>
                <th width="3%">
                    <Check 
                        v-model="masterCheck" 
                        @change="checked => $emit('handlerMasterSelect', checked)"
                    />
                </th>
                <th><Ellipsis>Início da vigência</Ellipsis></th>
                <th><Ellipsis>Flag preço</Ellipsis></th>
                <th><Ellipsis>Preço apresentação</Ellipsis></th>
                <th><Ellipsis>Preço fração</Ellipsis></th>
                <th><Ellipsis>Edição</Ellipsis></th>
                <th><Ellipsis>Nº Atualização</Ellipsis></th>
                <th><Ellipsis>Alterado</Ellipsis></th>
                <!-- <th></th> -->
            </tr>
        </thead>
        <tbody>
            <tr v-for="(brasindiceUpdate, index) in brasindiceUpdates" :key="index">
                <td>
                    <Check 
                        :value="selecteds.includes(brasindiceUpdate.id)" 
                        @input="checked => !checked && masterCheck ? (masterCheck = false) : null"
                        @change="$emit('handlerSelect', brasindiceUpdate)" 
                    />
                </td>
                <td>{{ parseDate(brasindiceUpdate.start_in) }}</td>
                <td>{{ brasindiceUpdate.flag_preco }}</td>
                <td>{{ parseNumberToMoney(brasindiceUpdate.preco_apres) }}</td>
                <td>{{ parseNumberToMoney(brasindiceUpdate.preco_frac) }}</td>
                <td>{{ brasindiceUpdate.edicao }}</td>
                <td>{{ brasindiceUpdate.edicao_updt }}</td>
                <td>{{ parseDate(brasindiceUpdate.updated_at, true) }}</td>
                <!-- <td>
                    <div class="actions">
                        <Delete 
                            class="icon" 
                            v-b-tooltip.hover title="Excluir item"
                            @click="() => deleteItem(brasindiceUpdate)"
                        />
                    </div>
                </td> -->
            </tr>
        </tbody>
    </b-table-simple>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper';

export default {
    components: {
        Ellipsis: () => import('@/components/General/Ellipsis'),   
        Check: () => import('@/components/General/Check'),
        // Delete: () => import('@/assets/icons/delete.svg'),
        // Edit: () => import('@/assets/icons/edit.svg'),
    },
    props: {
        tableId: String,
        brasindiceUpdates: {
            type: Array,
            default: []
        },
        selecteds: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            masterCheck: false
        }
    },
    methods: {
        parseNumberToMoney,
        parseDate(date, complete = false) {
            return this.moment(date).format(complete ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')
        },
        deleteItem(brasindiceUpdate) {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir esta entrada?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    this.$emit('delete', brasindiceUpdate)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

</style>